import React, { FC } from 'react';
import { PortableText, PortableTextProps } from '@portabletext/react';
import * as styles from './ServiceTitle.module.scss';

interface Props {
    text: string;
    description?: PortableTextProps['value'] | string;
}

const ServiceTitle: FC<Props> = ({ text, description }) => {

    return (
        <section className={styles.serviceTitle}>
            <h1 className={styles.title}>
                {text}
            </h1>

            {
                description && (
                    <div className={styles.description}>
                        {
                            typeof description === 'string' ? (
                                <p>{description}</p>
                            ) : (
                                <PortableText value={description} />
                            )
                        }
                    </div>
                )
            }
        </section>
    );
};

export default ServiceTitle;