import { graphql, HeadFC } from 'gatsby';
import React, { FC } from 'react';
import ServiceTitle from '../components/Titles/ServiceTitle';

const ThanksPage: FC = () => {

  return (
    <main>
      <ServiceTitle text={'Merci pour votre email'} description="Nous allons reprendre contact avec vous dès que possible." />
    </main>
  );
};

export const Head: HeadFC = ({ data }: any) => {
  const { general } = data;
  
  return (
    <>
      <title>{general.title}</title>
      <meta name="description" content={general.description?.slice(0, 150)} />
    </>
  );
};

export const query = graphql`
  {
    general: sanityGeneral {
      title
      description
    }
  }
`;

export default ThanksPage;